import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Top",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/Top.vue"),
    meta: { title: "工程管理アプリ" },
  },
  {
    path: "/users",
    name: "User",
    component: () => import("../views/User.vue"),
  },
  {
    path: "/results",
    name: "Result",
    component: () => import("../views/Result.vue"),
  },
  {
    path: "/categories",
    name: "Category",
    component: () => import("../views/Category.vue"),
  },
  {
    path: "/workNames",
    name: "WorkName",
    component: () => import("../views/WorkName.vue"),
  },
  {
    path: "/workGroups",
    name: "WorkGroup",
    component: () => import("../views/WorkGroup.vue"),
  },
  {
    path: "/works/selectWorkGroup",
    name: "SelectWorkGroup",
    component: () => import("../views/SelectWorkGroup.vue"),
  },
  {
    path: "/cars",
    name: "Car",
    component: () => import("../views/Car.vue"),
  },
  {
    path: "/works",
    name: "Work",
    component: () => import("../views/Work.vue"),
  },
  {
    path: "/works/check",
    name: "WorkCheck",
    component: () => import("../views/WorkCheck.vue"),
  },
  {
    path: "/works/procedure",
    name: "WorkProcedure",
    component: () => import("../views/WorkProcedure.vue"),
  },
  {
    path: "/works/process",
    name: "WorkProcess",
    component: () => import("../views/WorkProcess.vue"),
  },
  {
    path: "/works/:uuid",
    name: "WorkDetail",
    component: () => import("../views/WorkDetail.vue"),
  },
  {
    path: "/works/workDetail/ranking",
    name: "WorkSpeedRanking",
    component: () => import("../views/WorkSpeedRanking.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
