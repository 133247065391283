











import { Component, Vue } from "vue-property-decorator";
import CMenu from "@/components/CMenu.vue";
import AuthModule from "@/store/auth";

@Component({
  name: "Header",
  components: {
    CMenu,
  },
})
export default class Header extends Vue {
  private get me() {
    return AuthModule.me;
  }

  private drawer = false;
}
