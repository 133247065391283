import { User } from "@/api";
import { Module, Mutation, VuexModule, Action, getModule } from "vuex-module-decorators";
import store from "@/store/index";

export interface IAuthModule {
  _me: User | null;
}

@Module({
  dynamic: true,
  store,
  name: "auth",
  namespaced: true,
  preserveState: localStorage.getItem("vuex") && JSON.parse(localStorage.getItem("vuex") as string).auth,
})
class AuthModule extends VuexModule implements IAuthModule {
  public _me: User | null = null;

  @Mutation
  public changeMe(me: User | null) {
    this._me = me;
  }

  @Action({ rawError: true })
  public commitMe(me: User | null) {
    this.context.commit("changeMe", me);
  }

  public get me() {
    return this._me || null;
  }
}

export default getModule(AuthModule);
