import Vue from "vue";
import { ValidationProvider, ValidationObserver, extend, localize } from "vee-validate";
import ja from "@/assets/ja.json";
import { required, email, integer } from "vee-validate/dist/rules";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore:
import VueAwesomeCountdown from "vue-awesome-countdown";
import VModal from "vue-js-modal";
import VueHtmlToPaper from "vue-html-to-paper";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

require("@/assets/styles/index.sass");

Vue.config.productionTip = false;
localize("ja", ja);
Vue.component("ValidationProcider", ValidationProvider);
extend("required", required);
extend("integer", integer);
extend("email", email);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
Vue.use(VueAwesomeCountdown, "vac");
Vue.use(VModal, { componentName: "VModal" });
Vue.use(VueHtmlToPaper, {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
    "https://unpkg.com/kidlat-css/css/kidlat.css",
  ],
  timeout: 1000,
  autoClose: true,
  windowTitle: "",
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
