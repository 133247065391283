


























































import { Component, Vue } from "vue-property-decorator";
import AuthModule from "@/store/auth";
import TokenUtil from "@/utils/TokenUtil";
// eslint-disable-next-line import/no-extraneous-dependencies
import { mdiCog } from "@mdi/js";

@Component({
  name: "CMenu",
})
export default class CMenu extends Vue {
  private get me() {
    return AuthModule.me;
  }

  private logout() {
    TokenUtil.removeToken();
    AuthModule.commitMe(null);
    this.$router.push("/");
  }

  private icons = {
    mdiCog,
  };
}
